<template>
  <div style="padding:10px;">
    <v-btn
      @click="onBack"
      color="primary"
      style="margin: 10px;"
    >
      volver
    </v-btn>
    <css-editor
      v-if="id"
      :config="prepareCssEditor(id)"
      :onSave="prepareURL"
    />
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="3"
        style="padding:5px;"
      >
        <id-selector
          label="CMDviewcontentID (NEW)"
          type="cmdviewcontentevents"
          :value="id"
          :onChange="(v) => {
            id = v
            prepareURL()
          }"
        />
        <!--v-text-field
          v-model="id"
          label="DviewcontentID (OLD)"
          @input="prepareURL"
          hide-details
          outlined
        /-->
      </v-col>
      <!--v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
          <v-select
            v-model="typeID"
            :items="types"
            outlined
            label="Empieza en"
            itemText="name"
            itemValue="id"
            hide-details
            @change="prepareURL"
          />
      </v-col-->
      <!--v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="category"
          label="Tipo show (1,2,3)"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col-->
      <!--v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
          <v-autocomplete
            v-model="locale"
            :items="languages"
            outlined
            chips
            small-chips
            label="Idioma"
            itemText="NormalName"
            itemValue="ID"
            hide-details
            @change="prepareURL"
          />
      </v-col-->
      <!--v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="addWeek"
          label="A�adir semana (0-actual, 1-pr�xima semana)"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col-->
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="bgColor"
          label="Color fondo"
          :onChange="handleChangeBGColor"
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="color1"
          label="Color 1"
          :onChange="handleChangeColor1"
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="color2"
          label="Color 2"
          :onChange="handleChangeColor2"
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="zoom"
          label="Zoom"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        style="text-align:center;padding:20px;"
      >
        <copy-url :url="url" />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
      <v-col
        cols="12"
        sm="12"
        lg="6"
        style="text-align: center !important;"
        class="preview"
      >
          <preview 
            v-if="parentWidth && url"
            :url="url"
            locale="es"
            :size="{
              w: 1920,
              h: 1080
            }"
            :parentWidth="parentWidth"
          />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
    </v-row>
  </div>
</template>
<script>
import utilsService from '@/services/utils'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
import IdSelector from './IdSelector'
import Preview from './Preview'
import CopyUrl from './CopyUrl'
import CssEditor from './cssEditor/Index'
export default {
  components: {
    IdSelector,
    Preview,
    CopyUrl,
    ColorPickerItem,
    CssEditor,
  },
  props: {
    onBack: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    typeID: '0',
    url: null,
    zoom: 100,
    id: null, //'61ba5270-9bfe-11e9-a660-51217a9d4465',
    locale: 'es',
    addWeek: 0,
    category: null,
    parentWidth: null,
    rows: 8,
    bgColor: null,
    color1: null,
    color2: null,
  }),
  computed: {
    types () {
      return [
        {
          id: '0',
          name: 'Hoy'
        },
        {
          id: '1',
          name: 'Lunes'
        },
      ]
    },
    languages () {
      return utilsService.getAllLanguages()
    },
  },
  mounted () {
    setTimeout(() => {
      this.parentWidth = document.getElementsByClassName('preview')[0].offsetWidth - 0
    }, 1000)
    this.prepareURL()
  },
  methods: {
    handleChangeColor2 (v) {
      this.color2 = v
      this.prepareURL()
    },
    handleChangeColor1 (v) {
      this.color1 = v
      this.prepareURL()
    },
    prepareURL () {
      if (!this.id) {
        this.url = null
        return
      }
      this.id = this.id.split(' ').pop('')
      this.url = 'https://widgets.dviewcontent.com/shows-event-cm/?'
      //this.url = 'http://localhost:5173/?'
        .concat(`&cmdviewcontentid=${this.id}`)
        .concat(`&addWeek=${this.addWeek ? this.addWeek : 0}`)
        .concat(`&typeWeek=${this.typeID}`)
        .concat(this.zoom && this.zoom.length > 0 && this.zoom !== '100' ? `&zoom=${this.zoom}`: '')
        .concat(`&locale=${this.locale.toLowerCase()}`)
        .concat(this.bgColor && this.bgColor.length > 0 ? `&bgcolor=${this.bgColor.replace('#','')}`: '')
        .concat(this.color1 && this.color1.length > 0 ? `&color1=${this.color1.replace('#','')}`: '')
        .concat(this.color2 && this.color2.length > 0 ? `&color2=${this.color2.replace('#','')}`: '')
        //.concat(this.category && this.category.length > 0 ? `&Show=${this.category}`: '')
    },
    prepareCssEditor (v) {
      if (!v) return
      return {
        id: v,
        type: 'cmDviewontentEventID',
        template: 'shows-event-cm',
        previewURL: this.url,
      }
    },
  },
}
</script>

